<template>
  <div
    class="c--shared_country-name-in-flag d-flex justify-content-start align-items-center country-name"
    :data-testid="`${countryName}-name`"
  >
    <div class="flag tw-shadow-sm" :class="{ 'country-list-item': countryFlagWidth === 37 }">
      <img
        src="/assets/images/placeholders/homepage-sim-flag.png"
        :data-src="countryFlagUrl"
        :alt="countryName"
        :width="countryFlagWidth"
        :height="aspectRatioHeight"
        v-lazy-load
      />
    </div>
    <p>
      {{ countryName }}
    </p>
  </div>
</template>

<script>
  export default {
    name: 'CountryNameInFlag',
    props: {
      countryFlagUrl: {
        type: String,
        required: true,
      },
      countryName: {
        type: String,
        required: true,
      },
      countryFlagWidth: {
        type: Number,
        required: false,
        default: 37,
      },
    },
    data() {
      return {
        aspectRatio: 4 / 3,
      }
    },
    computed: {
      aspectRatioHeight() {
        return parseFloat((this.countryFlagWidth / this.aspectRatio).toFixed(2))
      },
    },
  }
</script>

<style lang="scss" scoped>
  .country-name {
    width: 100%;
    padding-right: 12px;

    [dir='rtl'] & {
      padding-right: unset;
      padding-left: 12px;
    }

    p {
      margin: 0;
      font-size: 0.9375rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 1.3333;
      color: #4a4a4a;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .flag {
      &.country-list-item {
        max-height: 28px;
      }
      & + p {
        margin-left: 15px;

        [dir='rtl'] & {
          margin-left: unset;
          margin-right: 15px;

          @media screen and (max-width: 575px) {
            margin-left: unset;
            margin-right: 10px;
          }
        }

        @media screen and (max-width: 575px) {
          margin-left: 10px;
        }
      }
    }
  }
</style>
